/* primary color #dc3545 */
/* secondary color #ffffff */

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

a{
  text-decoration: none;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: none;
  box-shadow: none;
  outline: 0 none;
}

.cs-btn-primary {
  background-color: #dc3545 !important;
  color: white !important;
}

.cs-btn-primary:active {
  color: #dc3545 !important;
  background-color: white !important;
  border: 1px solid #dc3545 !important;
}

.cs-btn-secondary {
  color: #dc3545;
  background-color: white;
}

.cs-text-primary {
  color: #dc3545;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

td img {
  vertical-align: middle;
}

tr{
  vertical-align: middle;
}

td,th{
  font-size: 12px;
  text-align: center;
}

label{
  font-size: 12px;
}

main.table {
  width: 82vw;
  height: 90vw;
  backdrop-filter: blur(7px);
  border-radius: 8px;
}

.table__header {
  width: 100%;
  height: 5%;
  margin-left: 360px;
  margin-top: 50px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.table__body {
  width: 95%;
  max-height: 89%;
  border-radius: 0.6rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.333);
  overflow: auto;
  cursor: pointer;
}

.table__header1 {
  width: 100%;
  height: 5%;
  margin-left: 300px;
  margin-top: 50px;
  margin-bottom: 20px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

.hello {
  background-color: rgb(222, 235, 246);
}

.orders-list {
  background-color: rgb(222, 235, 246);
}

.orders-list > h5 {
  margin-left: 50px;
}

.profile-info {
  margin-left: 20px;
  background-color: rgb(218, 225, 231);
}

.profile-info > h5 {
  margin-left: 30px;
  margin-top: 30px;
}

.name {
  font-weight: bold;
  color: rgb(78, 76, 76);
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

p {
  margin-bottom: 20px;
}

ol {
  margin-bottom: 20px;
}

ol li {
  margin-bottom: 10px;
}

#x {
  text-decoration: underline;
  margin-bottom: 20px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
}

.faq-item {
  margin-bottom: 20px;
}

.question {
  font-weight: bold;
}

.answer {
  margin-top: 10px;
}
#it {
  font-size: small;
  font-weight: bold;
}
#in {
  font-size: small;
}
#pp {
  border-radius: 50%;
  border: 2px solid rgb(158, 158, 158);
  width: 40px;
  height: 40px;
  font-size: medium;
}
#button-addon2 {
  font-size: x-small;
}
.del {
  font-size: 12px;
  font-weight: bolder;
}
